import * as React from "react";

function Quote({
  quoteName,
  quoteText,
  quoteTitle,
  themeColor,
  quoteColor,
  quoteTextColor,
}) {
  const theme = (color) => {
    switch (color) {
      case "sunset":
        return "#C12026";

      case "marine":
        return "#419B87";

      case "marineDark":
        return "#2B7363";

      case "fossil":
        return "#555555";

      case "orange":
        return "#FF8500";

      case "yellowAvert":
        return "#FBD500";

      case "lime":
        return "#A5CF07";

      case "purpleAvert":
        return "#D41AD0";

      case "midBlue":
        return "#17607D";

      case "lightBlue":
        return "#4FCDFF";

      case "boostPink":
        return "#BD7AB2";

      default:
        return "#C12026";
    }
  };

  const quotesColor = theme(quoteColor);

  return (
    <div
      className={
        "relative bg-" +
        themeColor +
        " pt-10 pb-6 px-4 sm:px-6 lg:pt-10 lg:pb-6 lg:px-8  border-t-8 border-b-8 border-white"
      }
    >
      <div className="relative max-w-7xl mx-auto">
        <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
          <div className="flex v-screen">
            <div className="m-auto">
              <div className="flex v-screen">
                <div className="m-auto">
                  {/* <svg
                    className="h-12 w-12 flex v-screen p-2"
                    viewBox="0 0 227 212"
                    version="1.1"
                  > */}
                  <svg
                    className="h-14 w-14 flex v-screen p-2"
                    viewBox="0 0 23 18"
                    version="1.1"
                  >
                    <path
                      d="M1.4,17.2 C0.2,17.2 0,16.6 0,15.1 L0,8.9 L5.9,0 L9.4,0 L4.3,9.1 C4.3,9.1 7.5,9.1 7.7,9.1 C8.9,9.1 9.1,9.8 9.1,11.3 L9.1,15 C9.1,17.2 8.7,17.2 6.6,17.2 C6.6,17.2 1.9,17.2 1.4,17.2 Z"
                      id="Path"
                      fill={quotesColor}
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M13.7,17.2 C12.5,17.2 12.3,16.6 12.3,15.1 L12.3,8.9 L18.2,0 L21.7,0 L16.6,9.1 C16.6,9.1 19.8,9.1 20,9.1 C21.2,9.1 21.4,9.8 21.4,11.3 L21.4,15 C21.4,17.2 21,17.2 18.9,17.2 C18.9,17.2 14.3,17.2 13.7,17.2 Z"
                      id="Path"
                      fill={quotesColor}
                      fillRule="nonzero"
                    ></path>

                    {/* <path
                      d="M97.714,10.08 L97.714,47.947 C97.714,53.084 93.55,57.251 88.41,57.251 C70.084,57.251 60.121,76.041 58.741,113.131 L88.41,113.131 C93.55,113.131 97.714,117.293 97.714,122.436 L97.714,202.39 C97.714,207.527 93.55,211.688 88.41,211.688 L9.304,211.688 C4.164,211.688 0,207.527 0,202.39 L0,122.436 C0,104.656 1.791,88.331 5.32,73.92 C8.941,59.154 14.496,46.239 21.826,35.535 C29.374,24.541 38.817,15.908 49.893,9.897 C61.041,3.851 74,0.782 88.416,0.782 C93.55,0.782 97.714,4.947 97.714,10.08 Z"
                      fill={quotesColor}
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M217.646,0.782 C203.235,0.782 190.27,3.85 179.123,9.897 C168.046,15.908 158.603,24.541 151.056,35.535 C143.721,46.245 138.17,59.154 134.544,73.931 C131.015,88.341 129.23,104.655 129.23,122.435 L129.23,202.389 C129.23,207.526 133.395,211.687 138.528,211.687 L217.641,211.687 C222.774,211.687 226.938006,207.526 226.938006,202.389 L226.938006,122.435 C226.938006,117.292 222.774,113.13 217.641,113.13 L188.389,113.13 C189.751,76.04 199.575,57.25 217.641,57.25 C222.774,57.25 226.938006,53.083 226.938006,47.946 L226.938006,10.08 C226.944,4.947 222.78,0.782 217.646,0.782 Z"
                      fill={quotesColor}
                      fillRule="nonzero"
                    ></path> */}
                  </svg>
                </div>
              </div>
              <blockquote>
                <p
                  className={
                    "mb-3 text-2xl font-medium text-" +
                    quoteTextColor +
                    " mt-3 text-center  max-w-prose"
                  }
                >
                  {quoteText}
                </p>
              </blockquote>
              <div className="flex v-screen">
                <div className="m-auto">
                  {/* <svg
                    className="h-12 w-12 flex v-screen p-2"
                    viewBox="0 0 227 212"
                    version="1.1"
                  > */}
                  <svg
                    className="h-14 w-14 flex v-screen p-2"
                    viewBox="0 0 22 18"
                    version="1.1"
                  >
                    <path
                      d="M8.2,0.4 C9.4,0.4 9.6,1 9.6,2.5 L9.6,8.7 L3.7,17.6 L0.2,17.6 L5.3,8.5 C5.3,8.5 2.1,8.5 1.9,8.5 C0.7,8.5 0.5,7.8 0.5,6.3 L0.5,2.6 C0.5,0.4 0.9,0.4 3,0.4 C3,0.4 8.1,0.4 8.2,0.4 Z"
                      id="Path"
                      fill={quotesColor}
                      fillRule="nonzero"
                    ></path>

                    <path
                      d="M20.6,0.4 C21.8,0.4 22,1 22,2.5 L22,8.7 L16.1,17.6 L12.6,17.6 L17.7,8.5 C17.7,8.5 14.5,8.5 14.3,8.5 C13.1,8.5 12.9,7.8 12.9,6.3 L12.9,2.6 C12.9,0.4 13.3,0.4 15.4,0.4 C15.4,0.4 20.4,0.4 20.6,0.4 Z"
                      id="Path"
                      fill={quotesColor}
                      fillRule="nonzero"
                    ></path>

                    {/* <path
                      d="M217.904,0.515 L138.795,0.515 C133.659,0.515 129.491,4.679 129.491,9.819 L129.491,89.768 C129.491,94.907 133.652,99.072 138.795,99.072 L168.461,99.072 C167.084,136.162 157.118,154.949 138.795,154.949 C133.659,154.949 129.491,159.113 129.491,164.253 L129.491,202.12 C129.491,207.257 133.652,211.424 138.795,211.424 C153.206,211.424 166.168,208.35 177.316,202.31 C188.387,196.292 197.833,187.671 205.375,176.665 C212.704,165.964 218.261,153.055 221.884,138.281 C225.412,123.87 227.203004,107.551 227.203004,89.768 L227.203004,9.818 C227.208,4.679 223.035,0.515 217.904,0.515 Z"
                      id="Path"
                      fill={quotesColor}
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M88.672,0.515 L9.563,0.515 C4.432,0.515 0.265,4.679 0.265,9.819 L0.265,89.768 C0.265,94.907 4.432,99.072 9.563,99.072 L38.815,99.072 C37.45,136.162 27.627,154.949 9.563,154.949 C4.432,154.949 0.265,159.113 0.265,164.253 L0.265,202.12 C0.265,207.257 4.432,211.424 9.563,211.424 C23.973,211.424 36.941,208.35 48.089,202.31 C59.165,196.292 68.605,187.671 76.147,176.665 C83.489,165.964 89.044,153.055 92.668,138.275 C96.191,123.87 97.977,107.551 97.977,89.775 L97.977,9.818 C97.976,4.679 93.809,0.515 88.672,0.515 Z"
                      id="Path"
                      fill={quotesColor}
                      fillRule="nonzero"
                    ></path> */}
                  </svg>
                </div>
              </div>

              <footer className="mt-6">
                <p
                  className={
                    "text-base font-medium text-" +
                    quoteTextColor +
                    " black text-center"
                  }
                >
                  {quoteName}
                </p>
                <p
                  className={
                    "text-base font-medium text-" +
                    quoteTextColor +
                    " black text-center"
                  }
                >
                  {quoteTitle}
                </p>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quote;
